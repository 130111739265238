import { atom } from 'jotai'

import { GetMeResponse } from '@/typings/api'

export const meAtom = atom<GetMeResponse | undefined>(undefined)

export const setMeAtom = atom(null, (_get, set, info: GetMeResponse) => set(meAtom, info))

export const clearMeAtom = atom(null, (_get, set) => set(meAtom, undefined))

export const isSchoolAdminAtom = atom(false)

export const isWuAdminAtom = atom(false)

export const orgIdAtom = atom<string>('')
