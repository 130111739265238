"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STUDY_PLAN = exports.WORD_INFO = exports.USER_DATA = exports.PRODUCT_PRICE_KEY = exports.FULL_TEST_KEY = exports.EXAM_ANSWERS_KEY = exports.EXAM_PAPER_KEY = exports.PRODUCTS_KEY = exports.DECKS_KEY = exports.CARDS_KEY = exports.CHAPTERS_KEY = exports.COMMENTS_KEY = exports.COMPONENT_KEY = exports.LICENSABLE_MATERIALS_KEY = exports.SHAREABLE_MATERIALS_KEY = exports.Q_GROUP_KEY = exports.MATERIALS_KEY = exports.SALES_REPORT = exports.SHOP_KEY = exports.COURSES_KEY = exports.KLASSES_KEY = exports.STUDENTS_KEY = exports.ORG_KEY = exports.MANAGE_MATERIALS = exports.MANAGE_PRODUCTS = exports.MANAGE_SCHOOLS = exports.ADMIN_PRODUCTS = exports.ADMIN_KEY = void 0;
exports.ADMIN_KEY = 'admin';
exports.ADMIN_PRODUCTS = 'admin/products';
exports.MANAGE_SCHOOLS = 'manage/schools';
exports.MANAGE_PRODUCTS = 'manage/products';
exports.MANAGE_MATERIALS = 'manage/materials';
exports.ORG_KEY = 'orgs';
exports.STUDENTS_KEY = 'students';
exports.KLASSES_KEY = 'klasses';
exports.COURSES_KEY = 'courses';
exports.SHOP_KEY = 'shop';
exports.SALES_REPORT = 'sales_report';
exports.MATERIALS_KEY = 'materials';
exports.Q_GROUP_KEY = 'question_group';
exports.SHAREABLE_MATERIALS_KEY = 'shareable_materials';
exports.LICENSABLE_MATERIALS_KEY = 'licensable_materials';
exports.COMPONENT_KEY = 'component';
exports.COMMENTS_KEY = 'comments';
exports.CHAPTERS_KEY = 'chapters';
exports.CARDS_KEY = 'cards';
exports.DECKS_KEY = 'decks';
exports.PRODUCTS_KEY = 'products';
exports.EXAM_PAPER_KEY = 'exam_paper';
exports.EXAM_ANSWERS_KEY = 'exam_answers';
exports.FULL_TEST_KEY = 'full_test';
exports.PRODUCT_PRICE_KEY = 'product_price';
exports.USER_DATA = 'user_data';
exports.WORD_INFO = 'word/info';
exports.STUDY_PLAN = 'study_plan';
