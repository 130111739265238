import useAuth from '@/hooks/use-auth'
import Homepage from './home'
import getStaticProps from './home/get-static-props'

const Home = () => {
  const { user } = useAuth()

  if (!user) return null

  return <Homepage />
}

export { getStaticProps }
export default Home
