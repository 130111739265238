import { AxiosRequestConfig, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'

import fire, { FireConfigType } from '@/apis/fire'

const useNsSWR = <T>({
  apiConfig,
  swrConfig,
  includeHeaders = false,
  shouldCamelcaseData = true,
}: {
  apiConfig: FireConfigType | null
  swrConfig?: SWRConfiguration<T>
  includeHeaders?: boolean
  shouldCamelcaseData?: boolean
}): SWRResponse<T> => {
  const fetcher = async (apiConfig: AxiosRequestConfig) => {
    const { data, headers } = (await fire(apiConfig)) as AxiosResponse<T>

    let finalData = shouldCamelcaseData
      ? camelcaseKeys(data as any, { deep: true })
      : data

    return includeHeaders ? { data: finalData, headers } : finalData
  }

  return useSWR(apiConfig, fetcher, swrConfig)
}

export default useNsSWR
